<i18n>
ru:
  byte: байт
  exabyte: Эб
  gigabyte: Гб
  kilobyte: Кб
  megabyte: Мб
  petabyte: Пб
  terabyte: Тб
  yottabyte: Иб
  zettabyte: Зб
ua:
  byte: байт
  exabyte: Эб
  gigabyte: Гб
  kilobyte: Кб
  megabyte: Мб
  petabyte: Пб
  terabyte: Тб
  yottabyte: Иб
  zettabyte: Зб
us:
  byte: byte
  exabyte: EB
  gigabyte: GB
  kilobyte: kB
  megabyte: MB
  petabyte: PB
  terabyte: TB
  yottabyte: YB
  zettabyte: ZB
</i18n>

<template>
  <span v-html="formatted" />
</template>

<script setup lang="ts">
const {
  bytes,
  lowercase = true,
  fraction = 0
} = defineProps<{
  bytes: number
  fraction?: number
  lowercase?: boolean
}>()

const { translate } = useI18nSanitized()
const units = [
  'fileSize.byte',
  'fileSize.kilobyte',
  'fileSize.megabyte',
  'fileSize.gigabyte',
  'fileSize.terabyte',
  'fileSize.petabyte',
  'fileSize.exabyte',
  'fileSize.zettabyte',
  'fileSize.yottabyte'
]

const formatted = computed(() => bytesHumanReadable(bytes))

function bytesHumanReadable(bytes: number): string {
  let bytesFormatted = bytes

  const threshold = 1024
  let measure = 0

  while (Math.round(bytesFormatted) >= threshold) {
    bytesFormatted /= threshold
    measure++
  }

  const measureText = translate(units[measure])

  return `${bytesFormatted.toFixed(fraction)} ${lowercase ? measureText.toLowerCase() : measureText}`
}
</script>

<template>
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3606_1090)">
      <path
        d="M12.3027 5L12.0918 14.0823H10.2812L10.0615 5H12.3027ZM10 16.868C10 16.5438 10.1055 16.2726 10.3164 16.0544C10.5332 15.8304 10.832 15.7184 11.2129 15.7184C11.5879 15.7184 11.8838 15.8304 12.1006 16.0544C12.3174 16.2726 12.4258 16.5438 12.4258 16.868C12.4258 17.1805 12.3174 17.4488 12.1006 17.6728C11.8838 17.8909 11.5879 18 11.2129 18C10.832 18 10.5332 17.8909 10.3164 17.6728C10.1055 17.4488 10 17.1805 10 16.868Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
